html{
  background-color: #1D1D1D;
  scroll-behavior: smooth;
  overflow-x: hidden !important;
  width: 100vw !important;
}
.App {
  max-width: 100%;
  margin: 0;
  display: flex;
  flex-direction: column;
  background-color: #1D1D1D;
  color: #FFFFFF;
}
.app--content{
  display: flex;
  flex-direction: column;
  max-width: 100vw;
  justify-content: center;
  align-items: center;
  padding-left: 10%;
}
a{
  text-decoration: none;
}
h1, h2{
  font-family: Arial, Helvetica, sans-serif;
}
p{
  font-family: sans-serif;
}
.full{
  width: 100% !important;
}