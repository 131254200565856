.portfolio{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 92vw;
}
.img--container{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 35vh;
    width: 92vw;
}
.img--container img{
    width: 20%;
    height: 100%;
    object-fit: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.portfolio__image{
    width: 20%;
    height: 100%;
    object-fit: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.portfolio--heading{
    align-self: flex-start;
    font-size: 5vw;
    color: #08FDD8;
    padding-left: 5%;
    margin-bottom: 0;
}
.portfolio--para{
    font-size: 1.2em;
    margin-bottom: 6%;
    padding: 0 5%;
}
.portfolio--para a{
    color: #08FDD8;
}
.portfolio h3{
    color: #8D8D8D;
    margin: 3% 0 3% 5%;
    letter-spacing: 3px;
    float: left;
    align-self: flex-start;
}
@media only screen and (max-width: 768px){
    .portfolio--heading{
        font-size: 9vw;
    }
    .portfolio--para{
        font-size: 4vw;
    }
}
@media only screen and (max-width: 800px){
    .img--container{
        height: fit-content;
        flex-direction: column;
    }
    .portfolio__image{
        width: 100%;
        height: 20rem;
    }
}
@media only screen and (max-width: 777px){
    .portfolio--heading--VCE{
        width: 420px;
        align-self: start;
    }
}
@media only screen and (max-width: 768px){
    .portfolio--heading{
        font-size: 3.5vw;
    }
    .portfolio--para{
        font-size: 2.5vw;
    }
    .portfolio h3{
        font-size: 12pt;
        margin: 5vh 0;
    }
    .portfolio--heading--VCE{
        width: 400px;
    }
}
@media only screen and (max-width: 565px){
    .portfolio h3{
        margin-left: 0;
    }
}
@media only screen and (max-width: 530px){
    .portfolio--heading--captures{
        width: 350px;
    }
}

@media only screen and (max-width: 480px){
    .portfolio h3{
        font-size: 10pt;
    }
    .portfolio--heading--VCE{
        width: 350px;
    }
    .portfolio--heading--captures{
        width: 300px;
    }
}
@media only screen and (max-width: 425px){
    .portfolio--heading{
        font-size: 4vw;
    }
    .portfolio--para{
        font-size: 3vw;
    }
}

@media only screen and (max-width: 367px){
    .portfolio h3{
        font-size: 8pt;
    }
    .portfolio--heading--VCE{
        width: 305px;
    }
    .portfolio--heading--captures{
        width: 265px;
    }
}