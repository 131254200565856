.info--card{
    width: 40.5%;
    background-color: #2A2A2A;
    padding: 2% 4%;
    color: #ABABAB;
    margin: 3% 0;
}
.info--card h3{
    color: #FFFFFF;
}
@media only screen and (max-width: 800px){
    .info--card{
        width: auto;
    }
}