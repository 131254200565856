.contact {
  margin-top: 15%;
  display: flex;
  width: 100%;
  justify-content: center;
  height: 100vh;
}
.contact--interact {
  margin-top: 6%;
  display: flex;
  justify-content: space-evenly;
}
.contact--panel {
  display: flex;
  flex-direction: column;
  width: 40%;
}
.contact--map {
  width: 52%;
  height: 100vh;
}
.contact--interact h2 {
  margin-top: 1%;
  color: #08fdd8;
  font-size: 5vw;
  margin-bottom: 7.5%;
}
.btn--container {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  margin: 5% 0;
}
.contact--interact--btn {
  background-color: transparent;
  color: #08fdd8;
  border-color: #08fdd8;
  border-style: solid;
  border-width: thin;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  width: 35%;
  padding: 2% 1.5%;
  margin-top: 2.5%;
  font-size: 1.1em;
  font-weight: 100;
  background: linear-gradient(to left, #1d1d1d 50%, #08fdd8 50%) right;
  background-size: 200%;
  transition: 0.3s ease-out;
}
.contact--interact--btn:hover {
  background-position: left;
  color: #1d1d1d;
}
.contact--interact--btn a {
  color: inherit;
  display: block;
  width: 100%;
  height: 100%;
}
.contact--interact p {
  font-size: 1.2em;
}
.colour--at {
  color: #08fdd8;
}
.contact--interact--info {
  background-color: #2a2a2a;
  padding: 3%;
  margin-top: 10%;
  width: fit-content;
  height: min-content;
}
.contact--interact--info p {
  line-height: 1.75em;
  margin: 0;
  width: fit-content;
}
@media only screen and (max-width: 850px) {
  .contact {
    justify-content: space-between;
  }
  .contact--interact {
    flex-direction: column-reverse;
    margin: 2.5vh 0;
  }
  .contact--panel {
    width: 80%;
  }
  .contact {
    flex-direction: column;
    align-items: center;
    margin-top: 0;
    padding-left: 0;
  }
  .contact--interact {
    width: fit-content;
    margin-bottom: 5%;
    align-items: center;
  }
  .contact h2 {
    font-size: 6vw;
  }
  .contact p {
    font-size: 3vw;
  }
  .contact--interact h2,
  .contact--interact p {
    width: 100%;
  }
  .contact--interact--info {
    margin: 30px 0;
  }
}
@media only screen and (max-width: 400px) {
  .contact {
    margin-top: 10vw;
  }
  .contact--interact--btn a {
    font-size: 3.25vw;
  }
}
