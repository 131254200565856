body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html{
  background-color: #1D1D1D;
  scroll-behavior: smooth;
  overflow-x: hidden !important;
  width: 100vw !important;
}
.App {
  max-width: 100%;
  margin: 0;
  display: flex;
  flex-direction: column;
  background-color: #1D1D1D;
  color: #FFFFFF;
}
.app--content{
  display: flex;
  flex-direction: column;
  max-width: 100vw;
  justify-content: center;
  align-items: center;
  padding-left: 10%;
}
a{
  text-decoration: none;
}
h1, h2{
  font-family: Arial, Helvetica, sans-serif;
}
p{
  font-family: sans-serif;
}
.full{
  width: 100% !important;
}
.navbar{
    display: flex;
    flex-direction: column;
    height: 100vh;
    background-color: #181818;
    width: 125px;
    position: fixed;
    z-index: 5;
}
.logo--section{
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    align-items: center;
    height: 175px;
    padding: 15% 1%;
    background-color: #000000;
    margin-bottom: 2%;
}
.logo--section a{
    color: #FFFFFF;
}
.logo--section p{
    color: #909096;
    font-size: .8rem;
    width: 80%;
}
.logo--section--name{
    margin-top: 3%;
    font-size: 1.5rem;
    font-weight: 500;
}
.nav{
    display: flex;
    justify-content: center;
    align-items: center;
}
.nav--item{
    text-align: center;
    line-height: 5vh;
    color: #909096;
    height: 5vh;
    padding: 5% 0;
    font-size: 1.1em;
    border-color: #282828;
    border-top-style: solid;
    border-bottom-style: solid;
    border-width: 1px;
    width: 100%;
}
.nav--item:hover{
    color: #08FDD8;
}
.logo{
    width: 60px;
    height: 60px;
}
@media only screen and (max-width: 1300px) {
    .navbar{
        flex-direction: row;
        height: 10vh;
        width: 100vw;
        background-color: #000000;
        justify-content: space-between;
        z-index: 40;
    }
    .logo--section{
        flex-direction: row;
        height: 10vh;
        padding: 0;
        align-items: center;
        width: 15vw;
        margin-bottom: 0;
    }
    .nav--item{
        height: 10vh;
        line-height: 10vh;
        text-align: center;
        width: 10vw;
        padding-right: 7vw;
        font-size: 17.5pt;
        border: none;
        padding-bottom: 0;
    }
    .logo--section--name{
        display: none;
    }
    .logo--section--info{
        display: none;
    }
    .logo{
        width: 40px;
        height: 40px;        
    }
  }
  @media only screen and (min-width: 660px) {

  }
  @media only screen and (min-width: 840px) {

  }
@media only screen and (min-width: 1300px){
    .nav--item{
        font-size: 15pt;
    }
}
.header--content{
    display: flex;
    flex-direction: column;
    height: 100vh;
    justify-content: center;
    width: 80vw;
}
.intro--header--top{
    font-size: 5em;
}
.header--content h1{
    font-size: 6vw;
    margin: 0;
}
.header--content p{
    color: #8D8D8D;
    padding-left: .5vw;
    font-size: 1.5vw;
    font-weight: 400;
}
.css-typing{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}
.css-typing h1 {
    white-space: nowrap;
    overflow: hidden;
    margin-right: 0;
  }
  .css-typing h1:nth-child(1) {
    width: 10vw;
    margin-right: 0;
    -webkit-animation-delay: .1s;
    animation-delay: .1s;
    -webkit-animation: type .3s steps(40, end);
    animation: type .3s steps(40, end);
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
  }
  .css-typing h1:nth-child(2) {
    width: 60vw;
    opacity: 0;
    -webkit-animation: type2 1.5s steps(50, end);
    animation: type2 1.5s steps(50, end);
    -webkit-animation-delay: .5s;
    animation-delay: .5s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
  }
  
  .css-typing h1:nth-child(3) {
    width: 80vw;
    opacity: 0;
    -webkit-animation: type3 5s steps(20, end);
    animation: type3 2s steps(20, end);
    -webkit-animation-delay: 1.5s;
    animation-delay: 1.5s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
  }
  
  @keyframes type {
    0% {
      width: 0;
    }
    99.9% {
    }
    100% {
      border: none;
    }
  }
  
  @-webkit-keyframes type {
    0% {
      width: 0;
    }
    99.9% {
    }
    100% {
      border: none;
    }
  }
  
  @keyframes type2 {
    0% {
      width: 0;
    }
    1% {
      opacity: 1;
    }
    99.9% {
    }
    100% {
      opacity: 1;
      border: none;
    }
  }
  
  @-webkit-keyframes type2 {
    0% {
      width: 0;
    }
    1% {
      opacity: 1;
    }
    99.9% {
    }
    100% {
      opacity: 1;
      border: none;
    }
  }
  
  @keyframes type3 {
    0% {
      width: 0;
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  
  @-webkit-keyframes type3 {
    0% {
      width: 0;
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  
  @keyframes blink {
    50% {
      border-color: transparent;
    }
  }
  @-webkit-keyframes blink {
    50% {
      border-color: tranparent;
    }
  }
.header--content--btn{
    background-color: transparent;    
    color: #08FDD8;
    border-color: #08FDD8;
    border-style: solid;
    border-width: thin;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    cursor: pointer;
    width: 20vw;
    margin-top: 2.5vh;
    padding: 1% 1.5%;
    font-size: 1.3vw;
    font-weight: 200;
    background: linear-gradient(to left, #1D1D1D 50%, #08FDD8 50%) right;
    background-size: 200%;
    transition: .3s ease-out;
    align-self: flex-start;
}
.header--content--btn:hover{
    background-position: left;
    color: #1D1D1D;
}
.header--content--btn a{
  color: inherit;
  display: block;
  width: 100%;
}
@media only screen and (max-width: 1300px) {
  .app--content{
    padding-left: 0;
  }
}
@media only screen and (max-width: 800px) {
  .header--content{
    width: 80vw;
  }
  .header--content--btn{
    width: 30vw;
    font-size: 3vw;
  }
  .header--content--btn a{
    font-size: 3vw;
  }
  .header--content h1{
    font-size: 7.5vw;
  }
  .header--content p{
    font-size: 2.5vw;
  }
}

.portfolio{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 92vw;
}
.img--container{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 35vh;
    width: 92vw;
}
.img--container img{
    width: 20%;
    height: 100%;
    object-fit: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.portfolio__image{
    width: 20%;
    height: 100%;
    object-fit: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.portfolio--heading{
    align-self: flex-start;
    font-size: 5vw;
    color: #08FDD8;
    padding-left: 5%;
    margin-bottom: 0;
}
.portfolio--para{
    font-size: 1.2em;
    margin-bottom: 6%;
    padding: 0 5%;
}
.portfolio--para a{
    color: #08FDD8;
}
.portfolio h3{
    color: #8D8D8D;
    margin: 3% 0 3% 5%;
    letter-spacing: 3px;
    float: left;
    align-self: flex-start;
}
@media only screen and (max-width: 768px){
    .portfolio--heading{
        font-size: 9vw;
    }
    .portfolio--para{
        font-size: 4vw;
    }
}
@media only screen and (max-width: 800px){
    .img--container{
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
        flex-direction: column;
    }
    .portfolio__image{
        width: 100%;
        height: 20rem;
    }
}
@media only screen and (max-width: 777px){
    .portfolio--heading--VCE{
        width: 420px;
        align-self: start;
    }
}
@media only screen and (max-width: 768px){
    .portfolio--heading{
        font-size: 3.5vw;
    }
    .portfolio--para{
        font-size: 2.5vw;
    }
    .portfolio h3{
        font-size: 12pt;
        margin: 5vh 0;
    }
    .portfolio--heading--VCE{
        width: 400px;
    }
}
@media only screen and (max-width: 565px){
    .portfolio h3{
        margin-left: 0;
    }
}
@media only screen and (max-width: 530px){
    .portfolio--heading--captures{
        width: 350px;
    }
}

@media only screen and (max-width: 480px){
    .portfolio h3{
        font-size: 10pt;
    }
    .portfolio--heading--VCE{
        width: 350px;
    }
    .portfolio--heading--captures{
        width: 300px;
    }
}
@media only screen and (max-width: 425px){
    .portfolio--heading{
        font-size: 4vw;
    }
    .portfolio--para{
        font-size: 3vw;
    }
}

@media only screen and (max-width: 367px){
    .portfolio h3{
        font-size: 8pt;
    }
    .portfolio--heading--VCE{
        width: 305px;
    }
    .portfolio--heading--captures{
        width: 265px;
    }
}
.info--card{
    width: 40.5%;
    background-color: #2A2A2A;
    padding: 2% 4%;
    color: #ABABAB;
    margin: 3% 0;
}
.info--card h3{
    color: #FFFFFF;
}
@media only screen and (max-width: 800px){
    .info--card{
        width: auto;
    }
}
.about h2{
    color: #08FDD8;
    font-size: 5vw;
    margin-top: 1vw;
}
.about{
    margin-top: 8vh;
    display: flex;
    flex-direction: column;
    width: 82.5vw;
    margin-bottom: 5vw;
}
.about--me, .tutoring, .photography{
    display: flex;
    justify-content: space-between;
    margin-bottom: 7.5vh;
    
}
.about--me--content{
    width: 48%;
    height: 70vh;
    display: flex;
    flex-direction: column;
}
.skills--swirl{
    width: 39%;
    height: auto;
    display: flex;
    flex-direction: column;
    padding: 5%;
}
.left, .right{
    width: 70%;
    line-height: 1.75em;
    font-size: 20pt;
    color: #08fdd8;
    margin: 2% 0;
}
.left{
    align-self: flex-start;
}
.right{
    text-align: right;
    align-self: flex-end;
}
.tutoring--content, .tutoring--stats{
    width: 48%;
    height: 91vh;
}
.info--card--container{
    display: flex;
    justify-content: space-between;
}
.tutoring--content h2, .photography--content h2{
    margin-top: .5vh;
}
.photography--content, .photography--stats{
    width: 48%;
    height: 90vh;
}
.photography--img img{
    width: 100%;
    margin: 1% 0;
}
.photography--img{
    padding: 2% 4%;
}
footer{
    background: #373737;
    height: 3px;
}
#biology{
    height: 100%;
    background-color: #08fdd8;
    max-width: 90%;
}
#chemistry{
    height: 100%;
    background-color: #FF2253;
    max-width: 72%;
}
#english{
    height: 100%;
    background-color: #D26CD5;
    max-width: 67%;
}
#physics{
    height: 100%;
    background-color: #08fdd8;
    max-width: 61%;
}
#maths{
    height: 100%;
    background-color: #FF2253;
    max-width: 56%;
}
#lightroom{
    height: 100%;
    background-color: #08fdd8;
    max-width: 95%;
}
#nature{
    height: 100%;
    background-color: #FF2253;
    max-width: 72%;
}
#urban{
    height: 100%;
    background-color: #D26CD5;
    max-width: 66%;
}
#photoshop{
    height: 100%;
    background-color: #08fdd8;
    max-width: 56%;
}
.about p{
    font-size: 1.2em;
}
.confidence--bars{
    margin-bottom: 2.5%;
}
@media only screen and (max-width: 1300px){
    .about{
        flex-direction: column;
        margin-bottom: 0;
        width: 80vw;
    }
    .about--me--content{
        flex-direction: column;
        align-items: center;
    }
    .about--me,.photography,.tutoring{
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        padding: 0 0;
        height: auto;
    }
    .about--me{
        justify-content: space-between;
    }
    .about--me--content, .tutoring--content, .tutoring--stats, .photography--content, .photography--stats{
        align-items: flex-start;
        width: 100%;
        height: auto;
    }
    .about--me div{
        width: 80vw;
    }
    .about{
        position: relative;
        height: auto;
        min-height: 100em !important;
    }
    .skills--swirl{
        height: auto;
    }
 }
 @media only screen and (max-width: 800px){
    .info--card--container{
        flex-direction: column;
    }
}
.contact {
  margin-top: 15%;
  display: flex;
  width: 100%;
  justify-content: center;
  height: 100vh;
}
.contact--interact {
  margin-top: 6%;
  display: flex;
  justify-content: space-evenly;
}
.contact--panel {
  display: flex;
  flex-direction: column;
  width: 40%;
}
.contact--map {
  width: 52%;
  height: 100vh;
}
.contact--interact h2 {
  margin-top: 1%;
  color: #08fdd8;
  font-size: 5vw;
  margin-bottom: 7.5%;
}
.btn--container {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  margin: 5% 0;
}
.contact--interact--btn {
  background-color: transparent;
  color: #08fdd8;
  border-color: #08fdd8;
  border-style: solid;
  border-width: thin;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  width: 35%;
  padding: 2% 1.5%;
  margin-top: 2.5%;
  font-size: 1.1em;
  font-weight: 100;
  background: linear-gradient(to left, #1d1d1d 50%, #08fdd8 50%) right;
  background-size: 200%;
  transition: 0.3s ease-out;
}
.contact--interact--btn:hover {
  background-position: left;
  color: #1d1d1d;
}
.contact--interact--btn a {
  color: inherit;
  display: block;
  width: 100%;
  height: 100%;
}
.contact--interact p {
  font-size: 1.2em;
}
.colour--at {
  color: #08fdd8;
}
.contact--interact--info {
  background-color: #2a2a2a;
  padding: 3%;
  margin-top: 10%;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: -webkit-min-content;
  height: min-content;
}
.contact--interact--info p {
  line-height: 1.75em;
  margin: 0;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
@media only screen and (max-width: 850px) {
  .contact {
    justify-content: space-between;
  }
  .contact--interact {
    flex-direction: column-reverse;
    margin: 2.5vh 0;
  }
  .contact--panel {
    width: 80%;
  }
  .contact {
    flex-direction: column;
    align-items: center;
    margin-top: 0;
    padding-left: 0;
  }
  .contact--interact {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-bottom: 5%;
    align-items: center;
  }
  .contact h2 {
    font-size: 6vw;
  }
  .contact p {
    font-size: 3vw;
  }
  .contact--interact h2,
  .contact--interact p {
    width: 100%;
  }
  .contact--interact--info {
    margin: 30px 0;
  }
}
@media only screen and (max-width: 400px) {
  .contact {
    margin-top: 10vw;
  }
  .contact--interact--btn a {
    font-size: 3.25vw;
  }
}

.shop{
    margin-left: 18%;
    margin-right: 5%;
    border-color: transparent;
}
.shop--item{
    display: flex;
    width: 100%;
}
.shop--header{
    border-width: .05rem;
    font-family: sans-serif;
    line-height: 1.5rem;
    display: flex;
    justify-content: center;
}
.shop--header h1{
    font-size: 200pt;
    color: #2B2B2B;
    letter-spacing: -24px;
}
@media only screen and (max-width: 1300px) {
    .shop{
        margin: 125px 5% 0 5%;
    }
}
@media only screen and (max-width: 800px){
    .shopitem{
        display: flex;
        flex-direction: column !important;
        justify-content: center;
        align-items: center;
    }
    .shop--header h1{
        font-size: 120pt;
        letter-spacing: -15px;
    }
    .shop--header p{
        font-size: 2vh;
    }
    .shop{
        margin: 12.5vh 5% 0 5%;
    }
}
@media only screen and (max-width: 768px){
    .shop{
        margin-top: 15vh;
    }
}
@media only screen and (max-width: 410px){
    .shop--header h1{
        font-size: 100pt;
        letter-spacing: -13px;
    }
}
.shopitem{
    display: flex;
    flex-direction: row;
    padding: 5% 7.5%;
    border-top-color: #08fdd8;
    border-top-style: solid;
    background-color: #2A2A2A;
    margin: 2.5% 0 7.5% 0;    
    box-shadow: 0 2px 4px -1px rgb(0 0 0 / 20%), 0 4px 5px 0 rgb(0 0 0 / 14%), 0 1px 10px 0 rgb(0 0 0 / 12%);
}
.shopitem--img{
    display: flex;
    width: 50%;
    height: 80vh;
    background-color: #27211E;
    justify-content: center;
    text-align: center;
}
.shopitem--info{
    display: flex;
    flex-direction: column;
    width: 50%;
    font-family: sans-serif;
    color: #FFFFFF;
    padding-top: 5%;
    justify-content: center;
    align-items: left;
}
.shopitem--img, .shopitem--info{
    padding: 0 7%;
}
.product--title{
    color: #08FDD8;
    font-size: 2rem;
}
.shopitem--info p{
    line-height: 1.5rem;
}
.buy__btn{
    font-size: 1.1rem;
    font-weight: 400;
    left: 12.5%;
    padding: 2% 1%;
    border-width: thin;
    width: 35%;
    text-align: center;
    align-self: center;
    margin-top: auto;
}
.shopitem--buybtn{
    background-color: transparent;    
    border-width: thin;
    border-style: solid;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    cursor: pointer;
    width: 40%;
    margin-top: 2.5%;
    padding: 2% 3%;
    font-size: 1.1em;
    font-weight: 100;
    background-position: right;
    background-size: 200%;
    transition: .3s ease-out;
}
.shopitem--buybtn:hover{
    background-color: #1d1d1d;
}
.shopitem--buybtn a{
  color: inherit;
  display: block;
  width: 100%;
}
@media only screen and (max-width: 800px){
    .shopitem--img{
        width: 80%;
    }
    .shopitem--info{
        width: 80%;
    }
    .shopitem--info h2{
        font-size: 4vh;
    }
}
.buybtn--container{
    display: flex;
    justify-content: center;
    margin-top: 15%;
}
.price{
    font-size: 2rem;
}
@media only screen and (max-width: 768px){
    .shopitem p{
        line-height: 2rem;
        font-size: 3.5vw;
        font-weight: 300;
    }
}
.price{
    color: #08FDD8;
    font-weight: 400;
    font-size: x-large;
    margin-bottom: 0;
}
.product--title{
    color: #FFFFFF;
    font-weight: 400;
}
.product--desc{
    color: #a7a7a7;
}
.paypal__buttons{
    padding: 0 8% 20% 5%;
    text-align: left;
}
.hide--me i{
    height: 0;
    width: 0;
}
.paypal--shop{
    z-index: 1;
}
.accesslink{
    text-decoration: none;
    color: white;
}
.accesslink:hover{
    color: #08fdd8;
}
