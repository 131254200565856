.header--content{
    display: flex;
    flex-direction: column;
    height: 100vh;
    justify-content: center;
    width: 80vw;
}
.intro--header--top{
    font-size: 5em;
}
.header--content h1{
    font-size: 6vw;
    margin: 0;
}
.header--content p{
    color: #8D8D8D;
    padding-left: .5vw;
    font-size: 1.5vw;
    font-weight: 400;
}
.css-typing{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}
.css-typing h1 {
    white-space: nowrap;
    overflow: hidden;
    margin-right: 0;
  }
  .css-typing h1:nth-child(1) {
    width: 10vw;
    margin-right: 0;
    -webkit-animation-delay: .1s;
    animation-delay: .1s;
    -webkit-animation: type .3s steps(40, end);
    animation: type .3s steps(40, end);
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
  }
  .css-typing h1:nth-child(2) {
    width: 60vw;
    opacity: 0;
    -webkit-animation: type2 1.5s steps(50, end);
    animation: type2 1.5s steps(50, end);
    -webkit-animation-delay: .5s;
    animation-delay: .5s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
  }
  
  .css-typing h1:nth-child(3) {
    width: 80vw;
    opacity: 0;
    -webkit-animation: type3 5s steps(20, end);
    animation: type3 2s steps(20, end);
    -webkit-animation-delay: 1.5s;
    animation-delay: 1.5s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
  }
  
  @keyframes type {
    0% {
      width: 0;
    }
    99.9% {
    }
    100% {
      border: none;
    }
  }
  
  @-webkit-keyframes type {
    0% {
      width: 0;
    }
    99.9% {
    }
    100% {
      border: none;
    }
  }
  
  @keyframes type2 {
    0% {
      width: 0;
    }
    1% {
      opacity: 1;
    }
    99.9% {
    }
    100% {
      opacity: 1;
      border: none;
    }
  }
  
  @-webkit-keyframes type2 {
    0% {
      width: 0;
    }
    1% {
      opacity: 1;
    }
    99.9% {
    }
    100% {
      opacity: 1;
      border: none;
    }
  }
  
  @keyframes type3 {
    0% {
      width: 0;
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  
  @-webkit-keyframes type3 {
    0% {
      width: 0;
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  
  @keyframes blink {
    50% {
      border-color: transparent;
    }
  }
  @-webkit-keyframes blink {
    50% {
      border-color: tranparent;
    }
  }
.header--content--btn{
    background-color: transparent;    
    color: #08FDD8;
    border-color: #08FDD8;
    border-style: solid;
    border-width: thin;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    cursor: pointer;
    width: 20vw;
    margin-top: 2.5vh;
    padding: 1% 1.5%;
    font-size: 1.3vw;
    font-weight: 200;
    background: linear-gradient(to left, #1D1D1D 50%, #08FDD8 50%) right;
    background-size: 200%;
    transition: .3s ease-out;
    align-self: flex-start;
}
.header--content--btn:hover{
    background-position: left;
    color: #1D1D1D;
}
.header--content--btn a{
  color: inherit;
  display: block;
  width: 100%;
}
@media only screen and (max-width: 1300px) {
  .app--content{
    padding-left: 0;
  }
}
@media only screen and (max-width: 800px) {
  .header--content{
    width: 80vw;
  }
  .header--content--btn{
    width: 30vw;
    font-size: 3vw;
  }
  .header--content--btn a{
    font-size: 3vw;
  }
  .header--content h1{
    font-size: 7.5vw;
  }
  .header--content p{
    font-size: 2.5vw;
  }
}
